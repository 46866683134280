import React from "react"

const logoText = require("../assets/images/logo-text.svg")

const Header: React.FC = () => {
  return (
    <div className="c-header__logo">
      <img src={logoText} />
    </div>
  )
}
export default Header
